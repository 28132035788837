<template>
  <div class="container">
    <section>
      <v-row class="d-flex align-center">
        <v-col
          cols="12"
          md="5"
          class="
            d-flex
            flex-column
            justify-md-start
            align-md-start
            justify-center
            align-center
          "
        >
          <v-img
            max-width="70%"
            src="../../assets/sobre_territorio_bento_nucleo_01.png"
          ></v-img>
          <p style="font-size: 0.6em; width: 70%; text-align: center">
            Vista geral de Bento Rodrigues a partir do leste da comunidade,
            estando à esquerda da imagem a Capela de São Bento e à direita, a
            escola municipal. Trata-se da única fotografia que o atingido
            Antônio Geraldo Santos possui da comunidade anterior ao rompimento.
            Fonte: Jornal A Sirene, Ed. 7, outubro de 2016.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Características do núcleo urbano"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                A região de Bento Rodrigues foi ocupada em consequência da
                extração de ouro, conciliada com atividades agropastoris desde o
                século XVIII. A paisagem resultante das ações humanas nas
                extensões rurais (pastagens, roçados e áreas de mineração)
                mistura-se ao meio natural, caracterizando-se pelo entorno
                montanhoso coberto por mata e pela formação geológica da Serra
                do Caraça. O núcleo urbano, no entanto, foi implantado em área
                de planície delimitada pelos córregos Santarém (a sul) e Ouro
                Fino (a leste), passando a sudeste o Rio Gualaxo do Norte
                (margeando boa parte dos terrenos rurais da comunidade).
              </p>
              <p>
                Durante o período colonial e na época do império, pelo núcleo de
                Bento Rodrigues passava uma das ramificações da Estrada Real que
                ligava Ouro Preto e Mariana a Sabará. Tratava-se do segmento
                relativo à Rua São Bento, que compunha o importante caminho de
                escoamento da produção aurífera local, assim como dos produtos
                agrícolas, contribuindo para o abastecimento do mercado regional
                e de setores comerciais que se estendiam até o Rio de Janeiro.
                Porém, diante da diminuição da área de abrangência do município,
                consequente diminuição da população e da demanda por alimentos,
                somada à implementação das ferrovias e das rodovias, entre o
                final do século XIX e ao longo de boa parte do XX, o papel de
                Bento Rodrigues no cenário econômico regional foi também
                reduzido.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A Rua São Bento demarca o eixo de ocupação original (na direção
                norte e sul), ligando a Capela em devoção ao Santo de mesmo nome
                à Capela de Nossa Senhora das Mercês, implantada em ponto mais
                alto do povoamento. O traçado das vias foi se expandindo ao
                longo do tempo, e da Rua São Bento partem perpendicularmente
                outros caminhos internos, como a Rua Raimundo Muniz, surgida
                como beco no período setecentista, além da Rua Dona Olinda e Rua
                Nossa Senhora das Mercês, que já fazem parte do arruamento
                aberto a partir do século XX. O núcleo urbano de Bento
                caracterizava-se por ser compacto, e essa característica estava
                diretamente relacionada à dinâmica sociocultural e econômica do
                subdistrito, a exemplo das procissões com percurso breve de uma
                capela a outra e da logística de se cultivar em vários terrenos.
              </p>
              <p>
                O conjunto de edificações destacava-se por sua harmonia, havendo
                entre elas arquétipos (amostras) bem preservados dos séculos
                XVIII, XIX e início do XX, ao longo da Rua São Bento. No que diz
                respeito à implantação, as casas foram construídas sem recuo
                frontal ou com pequeno afastamento, havendo terrenos contendo
                mais de uma habitação, construída ao longo do tempo, em grande
                parte quando os filhos constituíam suas próprias famílias. O
                meio das quadras preservava extensos quintais produtivos, onde
                eram cultivados pomares, hortas e criados animais. Além das
                habitações, nos terrenos havia edificações acessórias de uso
                rural, entre elas principalmente galinheiros, chiqueiros,
                currais e paióis.
              </p>
              <p>
                A paisagem urbana caracterizava-se pelo predomínio de
                edificações de um pavimento, mantendo a relação urbana original
                onde as capelas destacavam-se no conjunto, seguindo a ordenação
                da época colonial, em que os edifícios oficiais e principalmente
                os religiosos ocuparam posição de destaque, implantados de forma
                isolada, definindo as relações de poder. As capelas de Bento
                Rodrigues balizaram assim o eixo estruturante e organizador do
                povoamento, definido pela Rua São Bento.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="
            d-flex
            flex-column
            justify-center
            align-center
            justify-md-end
            align-md-end
          "
        >
          <v-img
            max-width="70%"
            src="../../assets/sobre_territorio_bento_nucleo_02.png"
          ></v-img>
          <p style="font-size: 0.6em; width: 70%; text-align: center">
            Jabuticabeiras no quintal do atingido Mauro Marcos da Silva em
            2013/2014. Fonte: Jornal A Sirene, Ed. 30, setembro de 2018.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex align-center">
        <v-col
          cols="12"
          md="5"
          class="
            d-flex
            flex-column
            justify-md-start
            align-md-start
            justify-center
            align-center
          "
        >
          <v-img
            max-width="70%"
            src="../../assets/sobre_territorio_bento_nucleo_03.png"
          ></v-img>
          <p
            style="
              font-size: 0.6em;
              width: 70%;
              text-align: center;
              text-align: center;
            "
          >
            Jabuticabeira no quintal do atingido Mauro Marcos. Fonte: Jornal A
            Sirene, Ed. 30, setembro de 2018.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Além dos dois templos religiosos católicos, na comunidade se
                encontrava a igreja evangélica Assembleia de Deus. A Escola
                Municipal de Bento Rodrigues, o posto de saúde e dos correios,
                anexos a ela, e a quadra poliesportiva faziam parte das
                edificações de uso público. Ressalta-se que essas construções
                apresentavam dimensões e volumetria que não interferiam na
                relação de destaque que as capelas setecentistas exerciam no
                conjunto.
              </p>
              <p>
                No que diz respeito aos padrões das casas, as mais antigas
                preservaram a tipologia original, bastante simplificada, de
                características coloniais, com fachadas onde predominavam as
                áreas de alvenarias em relação aos vãos de portas e janelas. Na
                maioria das casas, havia vãos com verga reta, esquadrias
                simples, folhas compostas por tábuas de madeira, sendo parte com
                caixilharia e vidro. As mais novas receberam janelas e portas
                metálicas, seja com verga reta ou em arco.
              </p>
              <p>
                No processo de cadastramento, identificou-se o uso de vários
                sistemas construtivos em uma mesma edificação, podendo coexistir
                alvenarias de adobe ou pau a pique e alvenaria de tijolo
                queimado sobre embasamento de pedra. Era bastante comum o
                aproveitamento de alicerces de pedra remanescentes nos terrenos
                para receberem as paredes de novas construções. As casas mais
                novas, construídas em substituição a outras mais antigas, foram
                feitas com beirais simples, sem contrafeito e cachorro, apenas
                em caibro corrido (beiral com a mesma inclinação da água do
                telhado), com paredes de tijolinho, tijolo cerâmico furado
                (tijolo baiano) ou de blocos de concreto.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Quanto ao uso das edificações, no conjunto predominava o
                residencial permanente. No caso de comércio ou prestação de
                serviços, era desenvolvido pelos moradores, configurando uma
                atividade caseira, familiar, realizada em parte da própria
                habitação ou em construção anexa no terreno.
              </p>
              <p>
                Conforme descrito, o núcleo urbano de Bento Rodrigues era pouco
                adensado, com a presença predominante das áreas produtivas. Na
                Rua São Bento, os lotes preservaram a configuração original com
                testadas (frentes) menores e profundidades em maior dimensão. O
                cercamento entre os terrenos era em boa parte feito com estacas
                de madeira e fios de arame, também com varas de bambu. Em época
                mais recente, foram sendo feitos fechamentos com alvenaria,
                destacando-se os trechos remanescentes de muros de pedra do
                século XVIII.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="
            d-flex
            flex-column
            justify-center
            align-center
            justify-md-end
            align-md-end
          "
        >
          <v-img
            max-width="70%"
            src="../../assets/sobre_territorio_bento_nucleo_04.png"
          ></v-img>
          <p style="font-size: 0.6em; width: 70%; text-align: center">
            Foto da atingida Simaria Quintão em que se vê o pé de jabuticaba de
            Dona Marta, considerado o maior de Bento. Fonte: Jornal A Sirene,
            Ed. 30, setembro de 2018.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex align-center">
        <v-col
          cols="12"
          md="5"
          class="
            d-flex
            flex-column
            justify-md-start
            align-md-start
            justify-center
            align-center
          "
        >
          <v-img
            max-width="70%"
            src="../../assets/sobre_territorio_bento_nucleo_05.png"
          ></v-img>
          <p style="font-size: 0.6em; width: 70%; text-align: center">
            Vista de trecho do muro de pedras “envelopado” em meio ao alagamento
            do Dique S4. Fonte: Jornal A Sirene, Ed. 10, janeiro de 2017.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Quanto à largura das vias, é importante observar que seguiam o
                modelo dos núcleos coloniais, com ruas estreitas, abertas para
                passagem inicialmente de pedestres e animais de carga. Ao longo
                do tempo, vieram os carros de passeio e utilitários,
                caracterizando um trânsito local de pequena intensidade e baixa
                velocidade. Das janelas de suas casas, ou dos portões, os
                moradores se comunicavam, observavam as crianças que brincavam
                tranquilas na rua.
              </p>
              <p>
                A alimentação era feita com produtos coletados no próprio
                quintal, a partir de hortas e pomares. Desta forma, a produção
                familiar e compartilhada, resultante do modo de vida local,
                contribuía para a conformação da ocupação do núcleo e da
                paisagem do lugar.
              </p>
              <p>
                Em relação ao núcleo urbano, mesmo com o adensamento ocorrido a
                partir da década de 90 do século XX, foi mantida a ambiência que
                integrava de forma harmônica elementos da natureza e as áreas
                modificadas e trabalhadas pela população para sua sobrevivência.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Estruturas Remanescentes"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Assim como nas demais comunidades, o território de Bento
                Rodrigues foi ao longo dos anos e dos séculos, sobrepondo
                elementos como construções, ruas e sistemas produtivos de acordo
                com cada época vivenciada pelas gerações de moradores. A
                apropriação do território em cada tempo vivido é semelhante a
                desenhos feitos em uma folha de papel por várias pessoas nas
                diferentes épocas em que viveram. Quando a lama de rejeitos
                destruiu a comunidade, apagou essa sequência de registros que
                foi se acumulando com o passar do tempo.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-center align-start">
        <v-col cols="12" md="5" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_bento_nucleo_06.png"
          ></v-img>
          <p
            class="mt-2"
            style="font-size: 0.6em; width: 100%; text-align: center"
          >
            Vista de parte da área alagada e do trecho do muro de pedras
            “envelopado”. Foto: Flora Passos, 2018
          </p>
        </v-col>
        <v-col cols="12" md="5" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_bento_nucleo_07.png"
          ></v-img>
          <p
            class="mt-2"
            style="font-size: 0.6em; width: 100%; text-align: center"
          >
            Vista da parte superior da fachada da ruína da capela da antiga
            fazenda do Morro do Fraga. Foto: Cristiano Sales / Jornal A Sirene,
            2016.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex align-center">
        <v-col
          cols="12"
          md="5"
          class="
            d-flex
            flex-column
            justify-md-start
            align-md-start
            justify-center
            align-center
          "
        >
          <v-img
            max-width="80%"
            src="../../assets/sobre_territorio_bento_nucleo_08.png"
          ></v-img>
          <p style="font-size: 0.6em; width: 80%; text-align: center">
            Vista de ruína de uma antiga fazenda situada entre o Morro do Fraga
            e o Córrego Ouro Fino. Foto: Cristiano Sales / Jornal A Sirene,
            2016.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O conjunto de estruturas remanescentes do antigo rancho de
                tropeiros (estalagem), denominado “Curral de Pedras”, faz parte
                desses registros das camadas que foram sendo construídas em
                Bento Rodrigues, apresentando valor histórico e arquitetônico
                deste local. Trata-se do cocho e do muro de pedras que possuía
                parte construída com lajes verticais, situados próximo da
                entrada a sul do território e da ponte que havia sobre o Córrego
                Santarém. Ressalta-se que as estruturas eram extremamente
                significativas para a história local e faziam parte do roteiro
                turístico dos Caminhos da Estrada Real. O cercamento com lajes
                verticais foi um recurso usado raramente no período colonial,
                sendo que, além do exemplar de Bento Rodrigues, estruturas
                semelhantes foram encontradas na região de Mariana e Ouro Preto
                somente no distrito de Rodrigo Silva, em Ouro Preto/MG, entre as
                ruínas da Fazenda Capão do Lana. O muro de pedras foi em um
                primeiro momento atingido pela lama de rejeitos e em um segundo
                momento teve parte de sua extensão submersa pelo lago do dique
                S4. A estrutura foi antes recoberta por sacos de rejeito
                empilhados, sendo denominado esse recurso pela empresa Samarco
                de “técnica de envelopamento”.
              </p>
              <p>
                Entre os elementos que restaram de época anterior, está o
                conjunto da Fazenda do Morro do Fraga, relacionado à ocupação do
                território de Bento Rodrigues, tendo surgido no século XVIII,
                decorrente da expansão das áreas de mineração do ouro, quando a
                produção das lavras próximas à sede de Mariana diminuiu. Ao
                longo dos anos, na fazenda, passaram a ser desenvolvidas
                atividades agrárias ao mesmo tempo que a mineração. Pelas terras
                da antiga fazenda encontram-se ruínas de estruturas de
                mineração, de edificações, pátio e moinho, além das ruínas da
                Capela de São Gregório e Nossa Senhora do Livramento. A partir
                da década de 60 do século XX, terrenos do Morro do Fraga foram
                explorados pela empresa Novelis do Brasil. Atualmente, a área da
                fazenda é de propriedade da mineradora Vale S.A.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Segundo a história oral, donos das fazendas da região de Bento
                Rodrigues e de outras próximas dali possuíam também propriedades
                no núcleo urbano. Seu Filomeno da Silva, atingido da comunidade,
                conta que os bisavôs paternos eram escravizados da Fábrica Nova
                e, alforriados, ganharam de seus antigos donos a casa em que
                habitavam. Da mesma forma, os bisavôs maternos adquiriram a
                moradia de seus antigos donos, proprietários do Morro do Fraga.
              </p>
              <p>
                A história conta ainda que as terras onde se encontra a ruína da
                capela, bem como a fazenda próxima ao Córrego Ouro Fino
                pertenceram ao casal de fazendeiros, Sr. Georvasio e Dona
                Olinda, que não tiveram filhos. As terras foram doadas por eles
                à Arquidiocese de Mariana e, em 1916, a fazenda foi cedida a um
                garimpeiro de nome desconhecido. Por sua vez, o garimpeiro cedeu
                as terras para Carlos Pinto, engenheiro e professor da Escola de
                Minas de Ouro Preto, que demarcou todo o terreno como se fosse
                de sua propriedade. O interesse de Carlos Pinto era a exploração
                das minas de ouro ali existentes. A apuração, separação e
                beneficiamento eram feitos em um engenho próximo à fazenda do
                Ouro Fino. Em 1938, Carlos Pinto paralisou a exploração, e as
                terras ficaram abandonadas. Depois de seu falecimento, as peças
                do engenho onde se fazia a apuração e as da usina que gerava
                energia para o beneficiamento foram vendidas por seu neto para
                um comprador de Mariana. Próximas do antigo engenho e da fazenda
                Ouro Fino, encontram-se cachoeiras e pouco do calçamento de
                pedra pertencente ao primeiro acesso a Bento Rodrigues, chamada
                de estrada de carro de boi nos séculos anteriores.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="
            d-flex
            flex-column
            justify-center
            align-center
            justify-md-end
            align-md-end
          "
        >
          <v-img
            max-width="70%"
            src="../../assets/sobre_territorio_bento_nucleo_09.png"
          ></v-img>
          <p style="font-size: 0.6em; width: 70%; text-align: center">
            Vista de cachoeira existente em meio à região de mata, próxima da
            antiga fazenda. Foto: Cristiano Sales / Jornal A Sirene, 2016.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex align-center">
        <v-col
          cols="12"
          md="5"
          class="
            d-flex
            flex-column
            justify-md-start
            align-md-start
            justify-center
            align-center
          "
        >
          <v-img
            max-width="70%"
            src="../../assets/sobre_territorio_bento_nucleo_10.png"
          ></v-img>
          <p style="font-size: 0.6em; width: 70%; text-align: center">
            Vista do poço de uma cachoeira existente em meio à região de mata,
            próxima da antiga fazenda. Foto: Cristiano Sales / Jornal A Sirene,
            2016.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A Fazenda Cruz das Almas também está entre as propriedades
                rurais antigas que possuíam estruturas remanescentes de épocas
                anteriores. Localizava-se muito próxima do núcleo urbano de
                Bento, a sul, cuja sede foi construída por Afonso Bretas.
                Posteriormente, ficou conhecida como Fazenda Muller quando
                passou a pertencer à família de sobrenome Muller, que por sua
                vez vendeu a propriedade para a Samarco. Nas terras havia um
                conjunto de edificações de uso rural, além de uma casa com amplo
                alpendre na fachada. Possuía ainda estruturas referentes a um
                haras que funcionou no local. A área da Fazenda, da mesma forma
                que um trecho do muro de pedras do antigo rancho, encontra-se
                submersa pelo lago do dique S4.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira <sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <strong>Referências Bibliográficas</strong>
            </p>
            <p>
              CASTRIOTA, Leonardo Barci. Dossiê de Tombamento de Bento
              Rodrigues. UFMG. Belo Horizonte, 2019. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento"
                class="pink--text"
                >https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento</a
              >. Acesso em: 30 mar. 2022.
            </p>
            <p>
              PÓLEN Consultoria Patrimônio e Projetos Ltda. Diagnóstico
              preliminar dos bens culturais identificados no território atingido
              em Mariana pelo rompimento da barragem de Fundão. Mariana, 2017.
            </p>
            <p>
              TEIXEIRA, Vanessa Cerqueira. Fé e cultura barroca sob o manto
              mercedário: devoção, religiosidade e vivências a partir da
              irmandade de Nossa Senhora das Mercês de Mariana (1749-1822). 220
              f. 2017. Dissertação (Mestrado em História) — Instituto de
              Ciências Humanas, Universidade Federal de Juiz de Fora Juiz de
              Fora-MG, 2017.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
